<template>
  <div class="page-content-wrap">
    <div class="container">
      <div class="post-content-wrap">
        <div class="page-title">
          <h1>Про Карту</h1>
        </div>
        <div class="page-content">
          <p>Карта вакцинації — це онлайн-карта, на якій усі охочі мають змогу знайти найближчі пункти вакцинації від COVID-19. Окрім цього, за допомогою інструментів Карти можна легко повідомити про будь-яке порушення або зловживання в процесі вакцинації.</p>
          <h3>Яка мета Карти?</h3>
          <p>Зробити процес вакцинації в Україні максимально прозорим: ми хочемо аби кожен українець мав змогу побачити існуючі пункти вакцинації та статистику використання вакцин ними. Своєчасна та достовірна інформація щодо вакцинації має бути доступна кожному без будь-яких перешкод.</p>
          <p>Також ми прагнемо домогтися усунення прогалини у методиці обліку даних, яку використовує Національна служба здоров’я України. Детальніше дивіться у розділі <router-link to="/lost">«Можливо втрачені дози»</router-link>.</p>
          <h3>Як користуватися Картою?</h3>
          <p>Щоб знайти медичний заклад, який вас цікавить, вручну збільште мапу та знайдіть місце, яке вас цікавить. Клацнувши по пункту вакцинації, ви побачите його назву, адресу, ПІБ керівника та контактні дані. Також ви матимете змогу побачити кількість вакцинованих осіб на пункті вакцинації та кількість <router-link to="/lost">можливо втрачених доз вакцини</router-link> із сортуванням за датами.</p>
          <p>Крім цього, на сторінці кожного пункту є можливість <u>повідомити про порушення</u> (залишити скаргу). Це стосується <u>будь-яких</u> порушень пов`язаних з вакцинацією і процесом щеплення. Наші юристи опрацьовують та надають відповідь на кожне звернення.</p>
          <h3>Звідки береться інформація на Карті та як часто вона оновлюється?</h3>
          <p>Дані на Карті автоматично завантажуються з Єдиного державного веб-порталу відкритих даних у режимі реального часу, які публікуються НСЗУ. Національна служба здоров'я у свою чергу використовує медичні записи про вакцинацію, що вносяться до електронної системи охорони здоров’я лікарями персонально по кожному пацієнту.</p>
          <h3>Чому кількість щеплень на Карті відрізняється від розміщеної на порталах Міністерства охорони здоров’я та РНБО?</h3>
          <p>МОЗ та РНБО самостійно збирають оперативну інформацію щодо проведених щеплень. Дані ж на Карті, як було зазначено вище, ґрунтуються на персоналізованих записах у електронній системі охорони здоров’я НСЗУ, отже вони є більш достовірними. Розбіжності, між даними МОЗ та НСЗУ можуть свідчити про можливі затримки при внесенні інформації до електронної системи.</p>
          <p>Карта створена за підтримки Посольства Франції в Україні. Ви можете підтримати розвиток наших проєктів <a href="https://shtab.net/pages/view/donate" target="_blank" rel="noreferrer">за цим лінком.</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>